import { Component, OnInit, Input, ChangeDetectorRef, } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ConfirmModal, INITIAL_CONFIRMMODAL_STATE } from '../../models/addnewusermodal';
import { INITIAL_ERROR_STATE } from '../../models/dialog.model';
import { AuthService } from '../../services/auth.service';
import { SharedStateService } from '../../services/shared-state.service';

@Component({
  selector: 'app-info-pop-up',
  templateUrl: './info-pop-up.component.html',
  styleUrls: ['./info-pop-up.component.scss']
})
export class InfoPopUpComponent implements OnInit {

  @Input() modal: ConfirmModal = INITIAL_CONFIRMMODAL_STATE;
  isInfoPopUp: boolean;
  isInfoPopUpSelected = false;
  appVersion: number;

  constructor(
    private sharedState: SharedStateService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private cdf:ChangeDetectorRef
    ) {
      this.spinnerService.show();
      this.authService.getSSMValue(environment.ssmParams.APPVERSION);
      this.sharedState.uploadStateData$.subscribe(response => {
        this.isInfoPopUpSelected = response.isInfoPopUp;
      });
  }
  ngOnInit(): void {
    this.sharedState.ssmIdleTimeData$.subscribe(response => { 
      if(response && response.ssmKey == environment.ssmParams.APPVERSION){    
        this.appVersion = response.ssmVal;
        this.spinnerService.hide();
        this.cdf.detectChanges();
      }  
    });
   }
  /**
  * (description): method called on click of close button
  * (parameter) : null
  * (memberof) : Component
  */
  onCancel() {
    this.isInfoPopUp = false;
    let notification = {
      isInfoPopUp: false
    }
    this.sharedState.setDashboardStateData(notification);
    this.modal = INITIAL_ERROR_STATE;
    this.modal.display = 'none';
  }

}
