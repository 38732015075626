import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantStrings,statusCodeString } from 'src/app/shared/helpers/constants';
import { HistoryDetailsModal, INITIAL_HistoryDetailsModal_STATE } from 'src/app/shared/models/dialog.model';
import { errorResponse } from 'src/app/shared/models/user.model';
import { SharedStateService } from 'src/app/shared/services/shared-state.service';
import { UploadService } from 'src/app/uploadfiles/upload.service';

@Component({
  selector: 'app-historydetails',
  templateUrl: './historydetails.component.html',
  styleUrls: ['./historydetails.component.scss']
})
export class HistoryDetailsComponent implements OnInit {

  @Input() modal: HistoryDetailsModal = INITIAL_HistoryDetailsModal_STATE;
  public value = true;

  //event emmitter to emit an event and pass the parameters on emit to another component receiving value
  @Output() event = new EventEmitter<boolean>();
  showSuccessModal: string;
  viewDetailsFlag: boolean = true;
  fileSizeUnit: number = 1024;
  getSizeUnit: string;
  filesCount: number;
  getSizeNumber: number;
  fileDetailsArr = [];
  receiveValue($event) {
    this.showSuccessModal = $event;
  }
  errorRes: errorResponse;
  historyDetails: any;
  resizeWindow: boolean = false;
  @Input() page: any;
  constantStringHistoryPage=constantStrings.historyPage;
  constantStringBatchPage=constantStrings.batchPage;
  fileName:string;
  statusPending=statusCodeString.PENDING;

  constructor(
    private uploadService: UploadService,
    private spinnerService: NgxSpinnerService,
    private sharedState: SharedStateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.sharedState.historyDetailsStateData$.subscribe(response => {
      this.viewHistoryDetailsAPI(response.uploadId)
    });
    if (window.innerWidth < 1919 && window.innerWidth >= 800) {
      this.resizeWindow = true;
    } else {
      this.resizeWindow = false;
    }

  }
  /**
  * (description): method called on resizing the window
  * (parameter) : event
  * (memberof) : HistoryDetailsComponent
  */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1919 && window.innerWidth >= 800) {
      this.resizeWindow = true;
    } else {
      this.resizeWindow = false;
    }
  }

  /**
  * (description): calling API to get view details data
  * (parameter) : uploadId
  * (memberof) : HistoryDetailsComponent
  */
  public viewHistoryDetailsAPI(uploadIdOrBatchId): any {
    this.fileDetailsArr = [];
    this.spinnerService.show();
    if (constantStrings.historyPage === this.page) {
      this.uploadService.getViewHistoryDetails(uploadIdOrBatchId).subscribe({
        next: result => {
          this.dataManipulation(result); 
        },
        error: err => {
          this.errorRes = err;
          this.spinnerService.hide();
        }
      });
    }
    else {
      this.uploadService.getBatchIdDetails(uploadIdOrBatchId).subscribe({
        next: result => {
          this.dataManipulation(result);
        },
        error: err => {
          this.errorRes = err;
          this.spinnerService.hide();
        }
      });
    }
  }
  /**
  * (description): function called for converting UTC to local timezone
  * (parameter) : dateTime
  * (memberof) : HistoryDetailsComponent
  */
  calculateLocalTime(dateTime) {
    const utcDatetime = dateTime;
    const utcDate = new Date(utcDatetime);
    const createdDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Convert to local timezone
    let fixedDatetime = createdDate.toISOString();
    return this.datePipe.transform(fixedDatetime, 'MM/dd/yyyy hh:mm:ss a');
  }

  /**
  * (description): function called for getting file unit
  * (parameter) : filesize
  * (memberof) : HistoryDetailsComponent
  */
  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = constantStrings.bytes;
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = constantStrings.bytes;
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = constantStrings.kb;
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = constantStrings.mb;
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = constantStrings.gb;
      }
    }
    return fileSizeInWords;
  }

  /**
  * (description): function called for getting file size
  * (parameter) : filesize
  * (memberof) : HistoryDetailsComponent
  */
  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  //This method is called on click of Close button to close the modal
  public onCancel(): void {
    this.event.emit(this.value);
    this.viewDetailsFlag = false;
    this.modal = INITIAL_HistoryDetailsModal_STATE;
    this.modal.display = 'none';    //close modal
  }

  dataManipulation(result) {
    this.historyDetails = result;
    this.historyDetails.uploadStartDateTime = this.calculateLocalTime(this.historyDetails.uploadStartDateTime);
    this.historyDetails.uploadEndDateTime = this.calculateLocalTime(this.historyDetails.uploadEndDateTime);
    this.historyDetails.statusUpdatedDateTime = this.calculateLocalTime(this.historyDetails.statusUpdatedDateTime);
    if (constantStrings.historyPage === this.page) {
    this.getSizeUnit = this.getFileSizeUnit(result.uploadSizeKB);
    this.getSizeNumber = this.getFileSize(result.uploadSizeKB);
    }
    else{
    this.getSizeUnit = this.getBatchFileSizeUnit(result.totalFileSizeKB);
    this.getSizeNumber = this.getFileSize(result.totalFileSizeKB);
    this.fileName=result.fileName?result.fileName:'';
    }
    this.spinnerService.hide();
    if (this.historyDetails.fileDetailsResponse) {
      this.filesCount = this.historyDetails.fileDetailsResponse.length;
      for (let size of this.historyDetails.fileDetailsResponse) {
        let sizeNumber = this.getFileSize(size.fileSizeKB)
        let sizeUnit = this.getFileSizeUnit(size.fileSizeKB)
        let obj = {
          fileName: size.fileName,
          fileSize: sizeNumber,
          fileUnit: sizeUnit
        }
        this.fileDetailsArr.push(obj)
      }
    }
  }

  getBatchFileSizeUnit(fileSize: number) {
    let fileSizeInWords = constantStrings.bytes;
    let fSize=fileSize * this.fileSizeUnit;
    if (fSize > 0) {
      if (fSize < this.fileSizeUnit) {
        fileSizeInWords = constantStrings.bytes;
      } else if (fSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = constantStrings.kb;
      } else if (
        fSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = constantStrings.mb;
      } else if (
        fSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = constantStrings.gb;
      }
    }
    return fileSizeInWords;
  }

}