import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    constructor() { }

    /**
     * (description): Sets the item in the session storage
     * (parameter) key: string
     * (parameter) : value
     * (memberof) : StorageService
     */
    public setItem(key: string, value: any): void {
        if (typeof value === 'string') {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }


    /**
     * (description): Sets the item in the local storage
     * (parameter) key: string
     * (parameter) : value
     * (memberof) : StorageService
     */
    public setItems(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }


    /**
     * (description):  Retrieves the item's string representation from the local storage
     * (parameter) key: string
     * (returns) : string
     * (memberof) : StorageService
     */
    public getItems(key: string): any {
        return JSON.parse(sessionStorage.getItem(key) || '{}');
    }


    /**
     * (description):  Removes the item from the local storage
     * (parameter) key: string
     * (memberof) : StorageService
     */
    public clearItems(key: string): void {
        sessionStorage.removeItem(key);
    }


    /**
     * (description):  Retrieves the item's string representation from the local storage
     * (parameter) key: string
     * (returns) : string
     * (memberof) : StorageService
     */
    public getItem(key: string): any {
        return sessionStorage.getItem(key);
    }



    /**
     * (description):  Retrieves the item(parse as JS object) from the session storage
     * (parameter) key: string
     * (returns) : any
     * (memberof) : StorageService
     */
    public getPersistedObject(key: string): any {
        return JSON.parse(sessionStorage.getItem(key) || '{}');
    }

    /**
     * (description):  Removes the item from the session storage
     * (parameter) key: string
     * (memberof) : StorageService
     */
    public clearItem(key: string): void {
        sessionStorage.removeItem(key);
    }


    /**
     * (description): Removes all the items from the session storage
     * (memberof) : StorageService
     */
    public clearAll(): void {
        sessionStorage.clear();
    }


    /**
     * (description):  Performs deep searching of an object whether it contains any property's value with matching text
     * (parameter) : obj
     * (parameter) searchText: string
     * (parameter) keySet: string
     * (parameter): boolean
     * (memberof) : StorageService
     */
    public searchObject(obj: any, searchText: string, keySet: string[]): boolean {
        const retValue = false;
        if (!!!keySet || keySet === [] || keySet.length === 0) {
            for (const key of Object.keys(obj)) {
                const value = obj[key];
                if (typeof value === 'object') {
                    this.searchObject(value, searchText, keySet);
                }
                if (typeof value === 'string' && value.indexOf(searchText) >= 0) {
                    return true;
                }
            }
        } else {
            for (const set of keySet) {
                const value = obj[set];
                if (typeof value === 'object') {
                    this.searchObject(value, searchText, keySet);
                }
                if (typeof value === 'string' && value.indexOf(searchText) >= 0) {
                    return true;
                }
            }
        }
        return retValue;
    }
}
