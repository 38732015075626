import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FiledashboardComponent } from './filedashboard.component';
import { FiledashboardRoutingModule } from './filedashboard-routing.module';
import { FailedToRetrievedataComponent } from './failed-to-retrievedata/failed-to-retrievedata.component';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopUpDialogueComponent } from './pop-up-dialogue/pop-up-dialogue.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MaterialPrimeModule } from './material.module';
import { FileDashboardFilterComponent } from '../filedashboardfilter/filedashboardfilter.component';
import { ConfirmRecallPopUpComponent } from './confirm-recall-pop-up/confirm-recall-pop-up.component';
import { FormsModule } from '@angular/forms';
import { UploadIncompleteComponent } from './uploadincomplte-dialog/uploadincomplte-dialog.component';
import { MissingtagSuccessModalComponent } from './missingtag-success-modal/missingtag-success-modal.component';
import {CustomCalendarHeaderComponent} from './custom-calendar-header/custom-calendar-header.component';

@NgModule({
  declarations: [FiledashboardComponent, FailedToRetrievedataComponent, PopUpDialogueComponent, FileDashboardFilterComponent,
    ConfirmRecallPopUpComponent,UploadIncompleteComponent, MissingtagSuccessModalComponent,CustomCalendarHeaderComponent],
  imports: [
    CommonModule,
    MaterialPrimeModule,
    BrowserModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatButtonToggleModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    FiledashboardRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FiledashboardModule { }
