import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AdminUserInfo } from '../../models/user.model';
export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
}

export interface Callback {
    callback(): void;

    callbackWithParam(result: any): void;
}

@Injectable({
    providedIn: 'root'
})

export class AwsAmplifyService {

    private attributes: any;
    private cognitouser!: CognitoUser;
    private callback!: CognitoCallback;
    public loggedIn: BehaviorSubject<boolean>;

    constructor(

    ) {
        Amplify.configure(environment);
        this.loggedIn = new BehaviorSubject<boolean>(false);
    }
    public confirmSignIn(user, code, mfaType) {
        return (Auth.confirmSignIn(user, code, mfaType));
    }
    public signUp(adminUser: AdminUserInfo): Observable<any> {
        this.attributes = {
            'email': adminUser.adminEmailId,
            'phone_number': adminUser.adminPhone,
            'family_name': adminUser.adminLastName,
            'given_name': adminUser.adminFirstName,
            'custom:group_name': 'ic-admin',
            'custom:imaging_center': adminUser.imagingCenterName,
            'custom:facility_name': adminUser.facilityId,
            'custom:MFA': "SMS",
            'custom:country_code': '+91',
        }

        return from(Auth.signUp({
            'username': adminUser.adminUserName,
            'password': adminUser.password,
            'attributes': this.attributes
        })
        );
    }
    public resendSignUp(userName: string): Observable<any> {
        return from(Auth.resendSignUp(userName));
    }
    /**
      * @description To help to setup the user MFA setting
      * aws service will setup user MFA setting
      * @param {*} user
      * @param {*} MFAType
      * @returns {Observable<any>}
      * @memberof AwsAmplifyService
      */
    public setupMFA(user, MFAType): Observable<any> {
        return from(Auth.setPreferredMFA(user, MFAType));
    }

    public setContactChannel(contactAttr) {
        return from(Auth.verifyCurrentUserAttribute(contactAttr));
    }


    public confirmSignUp(userName: string, code: string): Observable<any> {
        return from(Auth.confirmSignUp(userName, code));
    }
    /**
     * @description To login into application based on username and password *
     * @memberof AwsAmplifyService
     */
    public login = async (userName: string, password: string) => {
        return (await Auth.signIn(userName, password));
    }

    /**
     * @description To sign out from the application
     * @returns {Observable<any>}
     * @memberof AwsAmplifyService
     */
    public signOut(): Observable<any> {
        return from(Auth.signOut());
    }

    /**
     * @description To authenticate user based on the current session
     * @returns {Observable<boolean>}
     * @memberof AwsAmplifyService
     */
    public isAuthenticated(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser())
            .pipe(
                map(result => {
                    this.loggedIn.next(true);
                    return true;
                }),
                catchError(error => {
                    this.loggedIn.next(false);
                    return of(false);
                })
            );
    }


    /**
     * @description To get the logged-in user info
     * @returns this will hit the aws service and return with user details.
     * @memberof AwsAmplifyService
     */
    public getCurrentUserInfo() {
        return from(Auth.currentUserInfo());
    }

    /**
     * @description To get the logged-in user cognito object
     * @returns this will hit the aws service and return with user cognito object.
     * @memberof AwsAmplifyService
     */
    public getCurrentAuthenticatedUser() {
        return from(Auth.currentAuthenticatedUser());
    }

    /**
     * @description To retrieve coginito id token
     * @returns this will hit the aws service and return the coginito user id token.
     * @memberof AwsAmplifyService
     */
    public getIdToken() {
        return from(Auth.currentSession())
            .pipe(
                map(result => {
                    return result.getIdToken().getJwtToken();
                }),
                catchError(error => {
                    return of(error);
                })
            );
    }
    public onSubmitVerifyContact(contactAttr, verificationCode) {
        return from(Auth.verifyCurrentUserAttributeSubmit(contactAttr, verificationCode));
    }


}
