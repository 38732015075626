import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { environment } from "src/environments/environment";
import { ROLE } from "../helpers/constants";
import { environmentURL } from "../models/user.model";
import { AuthService } from "../services/auth.service";
import { SharedStateService } from "../services/shared-state.service";

@Injectable()
export class AuthGuard implements CanActivate {
    group: string;
    snnFacility: number;
    baseState: string;
    baseEnv: environmentURL;

    constructor(private router: Router,
        private authService: AuthService,
        private sharedState: SharedStateService) {
            const idToken = this.authService.getCognitoAccessTokenFromStorage();
            const decodedValue = this.authService.decode(idToken);
            this.baseState = decodedValue ? decodedValue['cognito:groups'][0] : '';
            if(this.baseState.toLowerCase() == ROLE.SNN_HIL_MANAGER.toLowerCase()){
                this.baseEnv = environment.snnClp
            } else {
                this.baseEnv = environment.clp
            }
         }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.sharedState.snnFacilityStateData$.subscribe(response => {
            if(response && response.facilityId) {
                this.snnFacility = response.facilityId;
            } else {
                this.snnFacility = null;
            }
        });
        this.snnFacility = this.authService.getItem('SNNFacility');
        const url: string = state.url;
        if((sessionStorage.getItem('isSessionActive') != null || sessionStorage.getItem('isSessionActive') != undefined )){
            if(sessionStorage.getItem('clpSession')){
            const idToken = this.authService.getCognitoAccessTokenFromStorage();
            const decodedValue = this.authService.decode(idToken);
            this.group = decodedValue ? decodedValue['cognito:groups'][0] : '';
                if (url === "/uploadfiles" || url === "/uploadstatus") {
                    if ((this.group.toLowerCase() == ROLE.IC_ADMIN) || (this.group.toLowerCase() == ROLE.IC_USER) || (this.group.toLowerCase() == ROLE.STAFF) || (this.group.toLowerCase() == ROLE.H_ADMIN) || (this.group.toLowerCase() == ROLE.SURGEON)) {
                        return true;
                    } else {
                        this.redirectHomepage();
                        return false;
                    }
                } else if (url === "/filedashboard" || url === "/history") {
                    if ((this.group.toLowerCase() == ROLE.IC_ADMIN) || (this.group.toLowerCase() == ROLE.IC_USER) || (this.group.toLowerCase() == ROLE.STAFF) || (this.group.toLowerCase() == ROLE.H_ADMIN) || (this.group.toLowerCase() == ROLE.SURGEON) || ((this.group.toLowerCase() == ROLE.SNN_HIL_MANAGER) && this.snnFacility)) {
                        return true;
                    } else {
                        this.redirectHomepage();
                        return false;
                    }
                } else if (url === "/auditlog") {
                    if ((this.group.toLowerCase() == ROLE.IC_ADMIN) || (this.group.toLowerCase() == ROLE.H_ADMIN) || ((this.group.toLowerCase() == ROLE.SNN_HIL_MANAGER) && this.snnFacility)) {
                        return true;
                    } else {
                        this.redirectHomepage();
                        return false;
                    }
                } else if (url === "/managefacilities") {
                    if ((this.group.toLowerCase() == ROLE.IC_ADMIN) || (this.group.toLowerCase() == ROLE.H_ADMIN) || (this.group.toLowerCase() == ROLE.SNN_HIL_MANAGER)) {
                        return true;
                    } else {
                        this.redirectHomepage();
                        return false;
                    }
                } else if (url === "/settings") {
                    if ((this.group.toLowerCase() == ROLE.IC_ADMIN) || (this.group.toLowerCase() == ROLE.H_ADMIN)) {
                        return true;
                    } else {
                        this.redirectHomepage();
                        return false;
                    }
                } else if (url === "/choosesite") {
                    if (this.group.toLowerCase() == ROLE.SNN_HIL_MANAGER) {
                        return true;
                    } else {
                        this.redirectHomepage();
                        return false;
                    }
                } else {
                    return true;
                }
            }else{
                let cloudfrontCLPURL = this.baseEnv.cloudFrontCLPSignInUrl;
                cloudfrontCLPURL = cloudfrontCLPURL.replace(/signin/g, 'application/product');
                window.location.href = cloudfrontCLPURL;
                return false;
            }
        } else {
           this.authService.logout();
           return false;
        }
    }
    redirectHomepage(){
        if (this.group.toLowerCase() == ROLE.SNN_HIL_MANAGER) {
            this.router.navigate(['/choosesite']);
        } else {
            this.router.navigate(['/filedashboard']);
        }
    }
}