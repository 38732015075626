import { ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileDashboardService } from '../filedashboard.service';

@Component({
  selector: 'app-custom-calendar-header',
  templateUrl: './custom-calendar-header.component.html',
  styleUrls: ['./custom-calendar-header.component.scss']
})
export class CustomCalendarHeaderComponent<D> implements OnInit {

  private _destroyed = new Subject<void>();
  minYearLabel: any;
  isMultiYearView = false;
  yearsPerPage = 24;
  isAlltagEmpty=false;

  constructor(private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef,
    private fileService: FileDashboardService) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnInit(): void {
    this.fileService.yearSelectedSubject$.subscribe((res) => {
      if (res) {
        this.isMultiYearView = false;
        this.periodYearLabel;
      }
    });

    this.fileService.missingTagSubject$.subscribe((res) => {
      if (res) {
        if(res===true){
          this.isAlltagEmpty=true;
        }
        else{
          this.isAlltagEmpty=false;
        }
      }
    });
  }

  get periodMonthLabel() {
    let monthVal = this._dateAdapter.getMonthNames('long')[this._dateAdapter.getMonth(this._calendar.activeDate)].toLocaleLowerCase()
    return monthVal.replace(/^./, monthVal[0].toUpperCase());
  }

  get periodYearLabel() {
    if (!this.isMultiYearView)
      return this._dateAdapter.getYear(this._calendar.activeDate);
    else
      return this.formatYearRange(...this.formatMinAndMaxYearLabels());
  }

  previousClicked(mode) {
    if (this._calendar.currentView != 'multi-year') {
      this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }
    else {
      if (mode != 'month') {
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(
          this._calendar.activeDate,
          -this.yearsPerPage,
        );
      }
    }
  }

  nextClicked(mode) {
    if (this._calendar.currentView != 'multi-year') {
      this._calendar.activeDate = mode === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
    else {
      if (mode != 'month') {
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(
          this._calendar.activeDate,
          this.yearsPerPage,
        );
      }
    }
  }

  currentPeriodClicked(view): void {
    if (view === 'year') {
      this._calendar.currentView = 'multi-year';
      this.isMultiYearView = true;
      this.fileService.multiYearSelectedSubject.next(true);
    }
    else {
      this._calendar.currentView = 'year';
      this.isMultiYearView = false;
      this.fileService.multiYearSelectedSubject.next(false);
    }
  }

  formatMinAndMaxYearLabels(): [minYearLabel: string, maxYearLabel: string] {
    const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
    const minYearOfPage =
      activeYear -
      this.getActiveOffset(
        this._dateAdapter,
        this._calendar.activeDate,
        this._calendar.minDate,
        this._calendar.maxDate,
      );
    const maxYearOfPage = minYearOfPage + this.yearsPerPage - 1;
    const minYearLabel = this._dateAdapter.getYearName(
      this._dateAdapter.createDate(minYearOfPage, 0, 1),
    );
    const maxYearLabel = this._dateAdapter.getYearName(
      this._dateAdapter.createDate(maxYearOfPage, 0, 1),
    );

    return [minYearLabel, maxYearLabel];
  }

  formatYearRange(start: string, end: string): string {
    return `${start} \u2013 ${end}`;
  }

  getActiveOffset<D>(
    dateAdapter: DateAdapter<D>,
    activeDate: D,
    minDate: D | null,
    maxDate: D | null,
  ): number {
    const activeYear = dateAdapter.getYear(activeDate);
    return this.euclideanModulo(activeYear - this.getStartingYear(dateAdapter, minDate, maxDate), this.yearsPerPage);
  }

  euclideanModulo(a: number, b: number): number {
    return ((a % b) + b) % b;
  }

  getStartingYear<D>(
    dateAdapter: DateAdapter<D>,
    minDate: D | null,
    maxDate: D | null,
  ): number {
    let startingYear = 0;
    if (maxDate) {
      const maxYear = dateAdapter.getYear(maxDate);
      startingYear = maxYear - this.yearsPerPage + 1;
    } else if (minDate) {
      startingYear = dateAdapter.getYear(minDate);
    }
    return startingYear;
  }

}
